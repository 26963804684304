import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Container,
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { DEFAULTS } from "../../../../Constants/Constants";

const validationSchemaPassword = yup.object({
  password: yup
    .string()
    .min(5, "Enter a valid password with at least 5 characters")
    .max(12, "Enter a valid password")
    .required("Enter a password"),
  passwordNew: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Confirm your password"),
});

const validationSchemaBank = yup.object({
  bankingName: yup
    .string()
    .min(5, "Enter a valid banking name with at least 5 characters")
    .max(30, "Enter a valid banking name")
    .required("Enter a banking name"),
  bankName: yup
    .string()
    .min(5, "Enter a valid bank name with at least 5 characters")
    .max(30, "Enter a valid bank name")
    .required("Enter a bank name"),
  bankAccNo: yup
    .string()
    .required("Enter an account number"),
  bankSwiftCode: yup.string(),
  bankRoute: yup
    .string()
    .required("Enter a bank routing code"),
});

interface Bank {
  bankingName: string;
  bankSwiftCode: string;
  bankName: string;
  bankAccNo: string;
  bankRoute: string;
}

export default function Profile() {
  const navigate = useNavigate();
  const token = localStorage.getItem("admin-token");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [formSuccess, setFormSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bankError, setBankError] = useState<string | null>(null);
  const [bankSuccess, setBankSuccess] = useState(false);
  const [bank, setBank] = useState<Bank>({
    bankingName: "",
    bankSwiftCode: "",
    bankName: "",
    bankAccNo: "",
    bankRoute: "",
  });

  const getBankDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${DEFAULTS.API_URL}agent/bank`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBank(response.data);
    } catch (error) {
      console.error("Error fetching bank details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  const formikPass = useFormik({
    initialValues: {
      password: "",
      passwordNew: "",
    },
    validationSchema: validationSchemaPassword,
    onSubmit: async (values) => {
      try {
        await axios.put(`${DEFAULTS.API_URL}user/password`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFormSuccess(true);
        setPasswordError(null);
        formikPass.resetForm();
      } catch (error: any) {
        setFormSuccess(false);
        setPasswordError(error.response?.data?.msg || "An unexpected error occurred.");
      }
    },
  });

  const formikBank = useFormik({
    initialValues: bank,
    enableReinitialize: true,
    validationSchema: validationSchemaBank,
    onSubmit: async (values) => {
      try {
        await axios.put(`${DEFAULTS.API_URL}agent/bank`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBankSuccess(true);
        setBankError(null);
        formikBank.resetForm();
        getBankDetails();
      } catch (error: any) {
        setBankSuccess(false);
        setBankError(error.response?.data?.msg || "An unexpected error occurred.");
      }
    },
  });

  return (
    <Container>
      <Typography variant="h5">Manage Profile</Typography>

      {/* Password Update Form */}
      <Box component="form" onSubmit={formikPass.handleSubmit} sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            {formSuccess && <Alert severity="success">Password updated successfully!</Alert>}
            {passwordError && <Alert severity="error">{passwordError}</Alert>}

            <Typography variant="h6">Change Password</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="password"
                  label="New Password"
                  type="password"
                  size="small"
                  value={formikPass.values.password}
                  onChange={formikPass.handleChange}
                  error={formikPass.touched.password && Boolean(formikPass.errors.password)}
                  helperText={formikPass.touched.password && formikPass.errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="passwordNew"
                  label="Confirm Password"
                  type="password"
                  size="small"
                  value={formikPass.values.passwordNew}
                  onChange={formikPass.handleChange}
                  error={formikPass.touched.passwordNew && Boolean(formikPass.errors.passwordNew)}
                  helperText={formikPass.touched.passwordNew && formikPass.errors.passwordNew}
                />
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Change Password
            </Button>
          </CardContent>
        </Card>
      </Box>

      {/* Bank Details Form */}
      
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent>
              {bankSuccess && <Alert severity="success">Bank details updated successfully!</Alert>}
              {bankError && <Alert severity="error">{bankError}</Alert>}

              <Typography variant="h6">Banking or Credit/Debit Card Details</Typography>
              <Typography variant="body2" style={{marginTop:10, marginBottom:10}}>
                Edit banking details or credit/debit card details for agent commission payout.
              </Typography>
              <form onSubmit={formikBank.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="bankingName"
                      label="Name"
                      size="small"
                      value={formikBank.values.bankingName}
                      onChange={formikBank.handleChange}
                      error={formikBank.touched.bankingName && Boolean(formikBank.errors.bankingName)}
                      helperText={formikBank.touched.bankingName && formikBank.errors.bankingName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="bankName"
                      label="Bank Name"
                      size="small"
                      value={formikBank.values.bankName}
                      onChange={formikBank.handleChange}
                      error={formikBank.touched.bankName && Boolean(formikBank.errors.bankName)}
                      helperText={formikBank.touched.bankName && formikBank.errors.bankName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="bankAccNo"
                      label="Account Number"
                      size="small"
                      value={formikBank.values.bankAccNo}
                      onChange={formikBank.handleChange}
                      error={formikBank.touched.bankAccNo && Boolean(formikBank.errors.bankAccNo)}
                      helperText={formikBank.touched.bankAccNo && formikBank.errors.bankAccNo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="bankRoute"
                      label="Routing No"
                      size="small"
                      value={formikBank.values.bankRoute}
                      onChange={formikBank.handleChange}
                      error={formikBank.touched.bankRoute && Boolean(formikBank.errors.bankRoute)}
                      helperText={formikBank.touched.bankRoute && formikBank.errors.bankRoute}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="bankSwiftCode"
                      label="Swift Code/BIC"
                      size="small"
                      value={formikBank.values.bankSwiftCode}
                      onChange={formikBank.handleChange}
                      error={formikBank.touched.bankSwiftCode && Boolean(formikBank.errors.bankSwiftCode)}
                      helperText={formikBank.touched.bankSwiftCode && formikBank.errors.bankSwiftCode}
                    />
                  </Grid>
                </Grid>
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Update
                </Button>
              </form>
            </CardContent>
          </Card>
        </Box>
      
    </Container>
  );
}
