import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField,Snackbar, Button, InputLabel,Chip, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import {pre} from '../../../../util/DisplayUtils'
import Heading from '../../Common/Header/Heading';
import DescriptionIcon from '@mui/icons-material/Description';
import { DEFAULTS } from '../../../../Constants/Constants'
import {convertFirestoreTimestampToDate,getShipDate} from '../../../../util/DisplayUtils'
type MyState = Array<any> | Record<string, any>;




function Quote() {

   const { id } = useParams();
  const [quote, setQuote] = useState<{[key: string]: any}>({});
  const [alert, setAlert] = React.useState<{ type: 'success' | 'error'; message: string; open: boolean }>({
    type: 'success',
    message: '',
    open: false,
  });
  const token = localStorage.getItem('admin-token');
  let navigate = useNavigate();
  const getQuote = async () => {
  await axios.get(DEFAULTS.API_URL+`quote/${id}`)
  .then(
    response => {
      console.log(response)
      if (response.data)
      {
        setQuote(response.data)
         
      }
    }
  )
    
}
useEffect(() => {

getQuote()

},[])
const updateStatus = async (quote: any, setAlert: (alert: { type: 'success' | 'error'; message: string; open: boolean }) => void) => {
  const request = {
    quoteId: Number(quote?.quoteId),
    status: "CUSTOMER REVIEW",
    emailTo: quote?.quoteDetails?.ship_email,
    dynamicData: {
      name: quote?.quoteDetails?.ship_name,
      amount: quote?.quoteDetails?.totalGrand,
      email:quote?.quoteDetails?.ship_email
    },
  };

  try {
    const response = await axios.post(`${DEFAULTS.API_URL}quote-status`, request,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      setAlert({ type: 'success', message: 'Quote status updated successfully!', open: true });
    } else {
      setAlert({ type: 'error', message: 'Failed to update quote status.', open: true });
    }
  } catch (error) {
    console.error('Error occurred:', error);
    setAlert({ type: 'error', message: 'An error occurred while updating the quote status.', open: true });
  }
};


const handleClose = () => setAlert({ ...alert, open: false });

  const handleUpdateStatus = async () => {
    await updateStatus(quote, setAlert);
  };


    return (
<>
<Heading icon={<DescriptionIcon color="secondary" />} text={`Quote # : ${pre(quote?.quoteDetails?.ship_type) + quote?.quoteId}`} />

       
            <Card variant="outlined">
                <CardContent>
                {(Object.keys(quote).length !== 0) && <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={3}>
                  <span className="Qhead">Quote Id </span><br />
                {pre(quote?.quoteDetails?.ship_type) + quote?.quoteId} 
                <Chip
    label={`V${quote?.quoteVersion}`}
    size="small"
    sx={{
      borderRadius: '40px',
      backgroundColor: 'orange',
      fontWeight: 'bold',
    }}
  />
</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Quote Date</span><br />
                  {convertFirestoreTimestampToDate(quote?.quoteVersionDate)}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Assigned To</span><br />
                  {quote?.agent?.agentName.toUpperCase()} ({quote?.agent?.agentId})</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Ship Date</span><br />
{getShipDate(quote?.quoteDetails?.ship_date)}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Mobile</span><br />
                {quote?.quoteDetails?.ship_mobile}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Name</span><br />
                  {quote?.quoteDetails?.ship_name?.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Email</span><br />
                  {quote?.quoteDetails?.ship_email?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Requester</span><br />
{quote?.quoteDetails?.requester_type?.toUpperCase()}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Shipment Type | Load Type</span><br />
                {quote?.quoteDetails?.ship_type.toUpperCase()} | {quote?.quoteDetails?.load_type.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Commodity</span><br />
                  {quote?.quoteDetails?.commodity.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Inco Term</span><br />
                {quote?.quoteDetails?.inco_term?.toUpperCase()}

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Currency</span><br />
{quote?.quoteDetails?.currency?.toUpperCase()}
                </Grid>
                

                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
               
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From</span><br />
                {quote?.quoteDetails?.from_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From Address</span><br />
                {quote?.quoteDetails?.from_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To</span><br />
                {quote?.quoteDetails?.to_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To Address</span><br />
                {quote?.quoteDetails?.to_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>

                {/* {(quote?.quoteType != 'local-courier') && (<Grid item xs={12} sm={12} md={4}>
                <span className="Qhead">Additionals</span><br />
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                {quote?.quoteDetails?.additionals?.map((item: any, index: number) => (
                 <tr><td>{item.label}</td><td>{item.required? 'YES':'NO'}</td></tr>
                 ))}
</table>
                </Grid>)} */}
                

                {(quote?.quoteType === 'local-courier') && <Grid item xs={12} sm={12} md={12}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Quantity</td>
                      <td>Packaging</td>
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      </tr>
 
    <tr >
      <td>{quote?.quoteDetails?.item?.quantity}</td>
                      <td>{quote?.quoteDetails?.item?.packaging}</td>
                      <td>{quote?.quoteDetails?.item?.weight.value}  {quote?.quoteDetails?.item?.weight.unit}</td>
                      <td>{quote?.quoteDetails?.item?.dimensions.height} X {quote?.quoteDetails?.item?.dimensions.width} X {quote?.quoteDetails?.item?.dimensions.length} {quote?.quoteDetails?.item?.dimensions.unit}</td>
  </tr>

</table></Grid>}
{(quote?.quoteDetails?.load_type === 'lcl' || quote?.quoteDetails?.load_type === 'ltl' || (quote?.quoteDetails?.ship_type === 'air')) && <Grid item xs={12} sm={12} md={12}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                    <td>Packaging</td>
                      <td>Quantity</td>
                      
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      <td>Rate($)</td>
                      <td>Margin(%)</td>
                      <td>Rate with Margin($)</td>
                      <td>Total($)</td>
                      </tr>
  {quote?.quoteDetails?.items?.map((item: any, index: number) => (
    <tr key={index}>
     
     <td>{Number(item.quantity) > 1 ? `${item.packaging}S` : item.packaging}</td>
                      <td>{item.quantity}</td>
                      <td>{item.weight.value}  {item.weight.unit}</td>
                      <td>{item.dimensions.height} X {item.dimensions.width} X {item.dimensions.length} {item.dimensions.unit}</td>
                      <td>{item.rate}</td>
                      <td>{item.margin}</td>
                      <td>{item.marginrate}</td>
                      <td>{item.ratetotal}</td>
                      </tr>
  ))}
  <tr><td colSpan={7}>Cargo Total($)</td><td>{quote?.quoteDetails?.totalCargo}</td></tr>
</table></Grid>}

{(quote?.quoteDetails?.load_type === 'fcl' || quote?.quoteDetails?.load_type === 'ftl') && <Grid item xs={12} sm={12} md={12}>
<span className="Qhead">Cargo Details</span><br /><br />
<table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Container</td>
                      <td>Container Type</td>
                      <td>Commodity</td>
                      <td>Weight</td>
                      <td>Quantity</td>
                      <td>Rate($)</td>
                      <td>Margin(%)</td>
                      <td>Rate with Margin($)</td>
                      <td>Total($)</td>
                      </tr>
                      <tr> 
                      
                    <td>{quote?.quoteDetails?.full_load_container}</td>
                    <td>{quote?.quoteDetails?.full_load_container_type}</td>

                    
                    <td>{quote?.quoteDetails?.commodity}</td>
                    <td>{quote?.quoteDetails?.full_load_weight} - {quote?.quoteDetails?.full_load_weight_unit}</td>
                    <td>{quote?.quoteDetails?.full_load_quantity}</td>
                    <td>{quote?.quoteDetails?.full_load_rate}</td>
                    <td>{quote?.quoteDetails?.full_load_margin}</td>
                    <td>{quote?.quoteDetails?.full_load_marginrate}</td>
                    <td>{quote?.quoteDetails?.full_load_ratetotal}</td>
                    </tr>
                      </table>
  
  

  
  
  
</Grid>}  



{(quote?.quoteType != 'local-courier') && (<Grid item xs={12} sm={12} md={12}>
                <span className="Qhead">Additionals</span><br />
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                <tr>
                      <td>Code</td>
                      <td>Label</td>
                      <td>Quantity</td>
                      <td>Rate($)</td>
                      <td>Margin(%)</td>
                      <td>Rate with Margin($)</td>
                      <td>Total($)</td>
                      </tr>
                {quote?.quoteDetails?.additionalItems?.map((item: any, index: number) => (
                 <tr>
                  <td>{item.code}</td>
                  <td>{item.label}</td>
                  <td>{item.quantity}</td>
                  <td>{item.rate}</td>
                  <td>{item.margin}</td>
                  <td>{item.marginrate}</td>
                  <td>{item.ratetotal}</td>
                </tr>
                 ))}
                 <tr><td colSpan={6}>Additionals Total($)</td><td>{quote?.quoteDetails?.totalAdditionals}</td></tr>
</table>
                </Grid>)}
                <Grid item xs={12} sm={12} md={12}>
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                <tr><td width={'90%'}>Grand Total($)</td><td>{quote?.quoteDetails?.totalGrand}</td></tr></table>
                
                <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
                  <Button
                  variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20 }}
                    onClick={()=>{navigate(`/portal/quote/edit/${quote.quoteId}`)}}
                  >
                    Edit Quote
                  </Button>
                  <Button
                  variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20, marginLeft: 20}}
                    onClick={handleUpdateStatus}
                  >
                    Send to Customer
                  </Button>
                
                </Grid>

                

                </Grid>}
                <Snackbar open={alert.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
</CardContent>
</Card>
</>
    );
  }

  interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
  }
  
  
  export default Quote;