import React, {useState, useEffect,useMemo} from 'react';
import { Container, Grid,Box, TextField, Button, InputLabel,Chip, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import {pre} from '../../../../util/DisplayUtils'
import Heading from '../../Common/Header/Heading';
import DescriptionIcon from '@mui/icons-material/Description';
import { DEFAULTS } from '../../../../Constants/Constants'
import {convertFirestoreTimestampToDate} from '../../../../util/DisplayUtils'
type MyState = Array<any> | Record<string, any>;


type adminType = {
    [key: string]: any;
  }

function QuoteAssign() {

   const { id } = useParams();
   const [isError,setError] =useState(false)
  const [quote, setQuote] = useState<{[key: string]: any}>({});
  const ADMINDETAILS: adminType | null = useMemo(() => {
    const adminDetailsString = localStorage.getItem('admin-details');
    return adminDetailsString ? JSON.parse(adminDetailsString) : null;
  }, []);

  const usertype = ADMINDETAILS?.type.toLowerCase()
  const token = localStorage.getItem('admin-token');
  let navigate = useNavigate();
  const getQuote = async () => {
  await axios.get(DEFAULTS.API_URL+`quote/assign/${id}`)
  .then(
    response => {
      console.log(response)
      if (response.data)
      {
        setQuote(response.data)
         
      }
    }
  )
    
}
useEffect(() => {

getQuote()

},[])
const assignQuote = async (quote: any) => {
  //setOpen(true);
  const request ={
    "quoteId" : Number(id),
    "status":"CUSTOMER REVIEW",
    "agentCommission":Number(ADMINDETAILS?.commission),
    "createdby":Number(ADMINDETAILS?.userid)
  }
  try {
    const response = await axios.post(DEFAULTS.API_URL + 'quote/assign', request, {
        headers: {
            Authorization: `Bearer ${token}`,
          }
    });
    
    if (response.status === 500) {
      console.error('Internal server error occurred');
      setError(true)
    } else {
console.log("response", response.data)
//setOpen(false);
if (response.data.status==="success") {
console.log("seuccess");
navigate('/portal/quotes')
} 
    }
  }
  catch (error) {
    console.error('Error occurred:', error);
   //setError(true)
  }
  
  //setOpen(false);
}

    return (
<>
<Heading icon={<DescriptionIcon color="secondary" />} text={`Quote # : ${pre(quote?.quoteDetails?.ship_type) + quote?.quoteId}`} />

       
            <Card variant="outlined">
                <CardContent>
                {(Object.keys(quote).length !== 0) && <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={3}>
                  <span className="Qhead">Quote Id </span><br />
                {pre(quote?.quoteDetails?.ship_type) + quote?.quoteId} 
                <Chip
    label={`V${quote?.quoteVersion}`}
    size="small"
    sx={{
      borderRadius: '40px',
      backgroundColor: 'orange',
      fontWeight: 'bold',
    }}
  />
</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Quote Date</span><br />
                  {/* {convertFirestoreTimestampToDate(quote?.quoteVersionDate)} */}
                  </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Assigned To</span><br />
                  {quote?.quoteAssigned?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Ship Date</span><br />
{quote?.quoteDetails?.ship_date}
                </Grid>
   {(usertype == "admin") && <>             <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Mobile</span><br />
                {quote?.quoteDetails?.ship_mobile}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Name</span><br />
                  {quote?.quoteDetails?.ship_name?.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Email</span><br />
                  {quote?.quoteDetails?.ship_email?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Requester</span><br />
{quote?.quoteDetails?.requester_type?.toUpperCase()}
                </Grid>
                </>}
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Shipment Type | Load Type</span><br />
                {quote?.quoteDetails?.ship_type.toUpperCase()} | {quote?.quoteDetails?.load_type.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Commodity</span><br />
                  {quote?.quoteDetails?.commodity.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Inco Term</span><br />
                {quote?.quoteDetails?.inco_term?.toUpperCase()}

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Currency</span><br />
{quote?.quoteDetails?.currency?.toUpperCase()}
                </Grid>
                

                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
               
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From</span><br />
                {quote?.quoteDetails?.from_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From Address</span><br />
                {quote?.quoteDetails?.from_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To</span><br />
                {quote?.quoteDetails?.to_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To Address</span><br />
                {quote?.quoteDetails?.to_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>


                

                {(quote?.quoteType === 'local-courier') && <Grid item xs={12} sm={12} md={12}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Quantity</td>
                      <td>Packaging</td>
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      </tr>
 
    <tr >
      <td>{quote?.quoteDetails?.item?.quantity}</td>
                      <td>{quote?.quoteDetails?.item?.packaging}</td>
                      <td>{quote?.quoteDetails?.item?.weight.value}  {quote?.quoteDetails?.item?.weight.unit}</td>
                      <td>{quote?.quoteDetails?.item?.dimensions.height} X {quote?.quoteDetails?.item?.dimensions.width} X {quote?.quoteDetails?.item?.dimensions.length} {quote?.quoteDetails?.item?.dimensions.unit}</td>
  </tr>

</table></Grid>}
{(quote?.quoteDetails?.load_type === 'lcl' || quote?.quoteDetails?.load_type === 'ltl' || (quote?.quoteType === 'air')) && <Grid item xs={12} sm={12} md={12}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                    <td>Packaging</td>
                      <td>Quantity</td>
                      
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>

                      </tr>
  {quote?.quoteDetails?.items?.map((item: any, index: number) => (
    <tr key={index}>
     
                      <td>{item.packaging}</td>
                      <td>{item.quantity}</td>
                      <td>{item.weight.value}  {item.weight.unit}</td>
                      <td>{item.dimensions.height} X {item.dimensions.width} X {item.dimensions.length} {item.dimensions.unit}</td>

                      </tr>
  ))}
  
</table></Grid>}

{(quote?.quoteDetails?.load_type === 'fcl' || quote?.quoteDetails?.load_type === 'ftl') && <Grid item xs={12} sm={12} md={12}>
<span className="Qhead">Cargo Details</span><br /><br />
<table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Container</td>
                      <td>Container Type</td>
                      <td>Commodity</td>
                      <td>Weight</td>
                      <td>Quantity</td>
                      
                      </tr>
                      <tr> 
                      
                    <td>{quote?.quoteDetails?.full_load_container}</td>
                    <td>{quote?.quoteDetails?.full_load_container_type}</td>

                    
                    <td>{quote?.quoteDetails?.commodity}</td>
                    <td>{quote?.quoteDetails?.full_load_weight} - {quote?.quoteDetails?.full_load_weight_unit}</td>
                    <td>{quote?.quoteDetails?.full_load_quantity}</td>
                   
                    </tr>
                      </table>
  
  

  
  
  
</Grid>}  



{(quote?.quoteType != 'local-courier') && (<Grid item xs={12} sm={12} md={12}>
                <span className="Qhead">Additionals</span><br />
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                <tr>
                      <td>Code</td>
                      <td>Label</td>
                      <td>Quantity</td>
                      
                      </tr>
                {quote?.quoteDetails?.additionalItems?.map((item: any, index: number) => (
                 <tr>
                  <td>{item.code}</td>
                  <td>{item.label}</td>
                  <td>{item.quantity}</td>
                 
                </tr>
                 ))}
                
</table>
                </Grid>)}
                <Grid item xs={12} sm={12} md={12}>
                                
                <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
                { isError && (
                <Alert severity='error'>Error occured. Try again</Alert>
              )} 

                {(usertype == "agent") && <Button
                  variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20}}
                    onClick={assignQuote}
                  >
                    Assign Quote to Me
                  </Button>}
                

                  <Button
                  variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20, marginLeft: 20}}
                    onClick={()=>{navigate("/")}}
                  >
                    Back to Dashboard
                  </Button>
                </Grid>

                

                </Grid>}
</CardContent>
</Card>
</>
    );
  }

  interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
  }
  
  
  export default QuoteAssign;