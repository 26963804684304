import React, {useState, useEffect, useMemo} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircle from '@mui/icons-material/AccountCircle';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import BusinessIcon from '@mui/icons-material/Business';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import CopyRight from '../../../../auth/Login/CopyRight'
import Person2Icon from '@mui/icons-material/Person2';
import WalletIcon from '@mui/icons-material/Wallet';
import ArticleIcon from '@mui/icons-material/Article';
import PasswordIcon from '@mui/icons-material/Password';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import GroupIcon from '@mui/icons-material/Group';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { DEFAULTS, CONFIG } from '../../../../Constants/Constants'
import Paper from '@mui/material/Paper';
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useSelector } from 'react-redux'
import { updateProfile,
  getProfile,
  selectProfile } from '../../../../features/profile/profileSlice'

  type customerProfileType = {
    [key: string]: any;
  }
  
  type adminType = {
    [key: string]: any;
  }

  interface MenuItem {
    name: 'Dashboard' | 'Customers' | 'Quotes' | 'Orders' | 'Users/Agents' | 'Commission' | 'Agent Registration';
    url: string;
    usertype: { admin: boolean; agent: boolean }; // Ensure exact match
  }
  const icons: Record<MenuItem['name'], JSX.Element> = {
    Dashboard: <DashboardIcon color="secondary" />,
    Customers: <PeopleIcon color="secondary" />,
    Quotes: <DescriptionIcon color="secondary" />,
    Orders: <ShoppingCartIcon color="secondary" />,
    'Users/Agents': <GroupIcon color="secondary" />,
    Commission: <MonetizationOnIcon color="secondary" />,
    'Agent Registration': <PersonAddIcon color="secondary" />,
  };

  function Navigation() {
    const ADMINDETAILS: adminType | null = useMemo(() => {
      const adminDetailsString = localStorage.getItem('admin-details');
      return adminDetailsString ? JSON.parse(adminDetailsString) : null;
    }, []);
  
    const usertype = ADMINDETAILS?.type.toLowerCase() as keyof MenuItem['usertype'];

  
    if (!usertype) {
      console.error("Invalid user type. Navigation cannot be rendered.");
      return null;
    }
  
    const MENU: MenuItem[] = CONFIG.MENU.map((item) => {
      if (Object.keys(icons).includes(item.name)) {
        return item as MenuItem;
      }
      console.warn(`Invalid menu item: ${item.name}`);
      return null;
    }).filter(Boolean) as MenuItem[];
  
    const filteredMenu = MENU.filter((item) => item.usertype[usertype]);

  
    return (
      <div style={{ height: '95vh' }}>
        <List>
          {/* Greeting */}
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AccountCircle color="secondary" />
              </ListItemIcon>
              Howdy! {ADMINDETAILS?.userProfileName}
            </ListItemButton>
          </ListItem>
          <Divider />
  
          {/* Dynamic Menu */}
          {filteredMenu.map((menuItem, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {icons[menuItem.name as keyof typeof icons]}
                </ListItemIcon>
                <Link href={menuItem.url} underline="none">
                  <ListItemText primary={menuItem.name} />
                </Link>
              </ListItemButton>
            </ListItem>
          ))}
          <Divider />
  
          {/* Static Links */}
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <PasswordIcon color="secondary" />
              </ListItemIcon>
              <Link href="/portal/profile"><ListItemText primary="Manage Profile" /></Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LiveHelpIcon color="secondary" />
              </ListItemIcon>
              <Link href="/assets/pdf/IKEOCEAN-Employee-handbook.pdf" target="_blank" rel="noopener noreferrer">
                <ListItemText primary="Employee Handbook" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon color="secondary" />
              </ListItemIcon>
              <Link href="/auth/logout"><ListItemText primary="Logout" /></Link>
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
        <CopyRight sx={{ mt: 5 }} />
      </div>
    );
  }
  

  

  export default Navigation;