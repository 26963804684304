import React , {useState} from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {Alert, Grid} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import LogoComponent from './LogoComponent'
import CopyRight from './CopyRight'
import * as yup from 'yup';
import axios from 'axios';
import Image from 'mui-image'
import { DEFAULTS } from '../../Constants/Constants'
type FormError = {
  status: string,
  msg:string
  // Add other properties if needed, for example:
  // message: string;
};
const theme = createTheme();

const validationSchema = yup.object({
  email: yup
  .string().email()
  .min(9, 'Enter a valid email')
  .max(50, 'Enter a valid email')
  .required('Enter an email'),
  password: yup
  .string()
  .min(5, 'Enter a valid password with atleast 5 characters')
  .max(12, 'Enter a valid password')
  .required('Enter a password')
});

export default function Login() {
  const navigate = useNavigate();
  const [formError, setFormError] = useState<FormError>({ status: '', msg:'' });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     console.log("values", values, DEFAULTS.API_URL)
     const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);

     const expensesListResp = async () => {

        await axios.post( DEFAULTS.API_URL +'admin/login', values)
        .then(
          response => {
            console.log(response)
            if (response.data.token)
            {
              localStorage.setItem('admin-token', response.data.token);
              localStorage.setItem('admin-details', JSON.stringify(response.data))
              navigate('/');
            }
            else {
              setFormError(response.data);
            }
          }
        )
          
      }
      expensesListResp()
    },
  });
  const isError = formError?.status === 'error';
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: 'url(https://ikeocean.com/wp-content/uploads/2020/05/png-1Containers.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            
            <LogoComponent />
            
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{backgroundColor:'#eb4034'}}
              >
                Sign In
              </Button>
              { isError && (
                <Alert severity='error'>{formError.msg}</Alert>
              )}
              <Grid container>
                <Grid item xs>
                  <Link href="/auth/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                {/* <Grid item>
                  <Link href="/auth/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>*/}
              </Grid> 
              <CopyRight sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}