import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Link, Outlet} from "react-router-dom";
import './index.css';
import App from './App';
import Auth from './auth/Auth';
import reportWebVitals from './reportWebVitals';
import ResetPassword from './auth/Login/ResetPassword'
import ProtectedRoute from './util/ProtectedRoute'
import Shipment from './portal/components/pages/Shipment/Shipment'
import ShipmentInfo from './portal/components/pages/Shipment/ShipmentInfo'
import CourierInfo from './portal/components/pages/Shipment/CourierInfo'
import Users from './portal/components/pages/User/Users'
import Agents from './portal/components/pages/User/Agents'
import Confirmation from './portal/components/pages/Shipment/Confirmation'
import Payments from './portal/components/pages/Payment/Payments'
import Wallet from './portal/components/pages/Wallet/Wallet'
import Quote from './portal/components/pages/Quote/Quote'
import QuoteDetail from './portal/components/pages/Quote/QuoteDetail'
import QuoteEdit from './portal/components/pages/Quote/QuoteEdit'
import QuoteComplete from './portal/components/pages/Quote/QuoteComplete'
import Shipments from './portal/components/pages/Shipments/Shipments'
import ShipmentDetail from './portal/components/pages/Shipments/ShipmentDetail'
import Tracking from './portal/components/pages/Shipments/Tracking'
import Profile from './portal/components/pages/Profile/Profile'
import Customers from './portal/components/pages/Customer/Customers'
import Customer from './portal/components/pages/Customer/Customer'
import ShipmentOrders from './portal/components/pages/Orders/Orders'
import Orders from './portal/components/pages/Quote/Orders'
import Order from './portal/components/pages/Quote/Order'
import CustomerRecord from './portal/components/pages/Customer/CustomerRecord'
import Help from './portal/components/pages/Help/Help'
import Reports from './portal/components/pages/Reports/Reports'
import Dashboard from './portal/components/pages/Dashboard/Dashboard'
import Commission from './portal/components/pages/Commission/Commission'
import MyCommission from './portal/components/pages/Commission/MyCommission'
import Login from './auth/Login/Login'
import ForgotPassword from './auth/Login/ForgotPassword';

import { store } from './store'
import { Provider } from 'react-redux'
import Logout from './auth/Login/Logout';
import QuoteAssign from './portal/components/pages/Quote/QuoteAssign';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
    <BrowserRouter basename={'/'}>
    <Routes>
        <Route path='/auth' element={<Auth />}>
            <Route path='login' element={<Login />} />
            
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='reset/:id' element={<ResetPassword />} />
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='logout' element={<Logout />} />
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='forgot-password' element={<ForgotPassword />} />
        </Route>

        <Route path='' element={<Provider store={store}><App /></Provider>}>
            <Route path='' element={
                <ProtectedRoute>
                    <Dashboard />
                </ProtectedRoute>
            } />
             <Route path='/portal/profile' element={
                <ProtectedRoute>
                    <Profile />
                </ProtectedRoute>
            } />
               <Route path='/portal/quotes' element={
                <ProtectedRoute>
                    <Quote />
                </ProtectedRoute>
            } />
                <Route path='/portal/customers' element={
                <ProtectedRoute>
                    <Customers />
                </ProtectedRoute>
            } />
                <Route path='/portal/customer/:id' element={
                <ProtectedRoute>
                <Customer />
                </ProtectedRoute>
            } />
            <Route path='/portal/customer/add' element={
                <ProtectedRoute>
                <CustomerRecord />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipments' element={
                <ProtectedRoute>
                    <Shipments />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipment/:id' element={
                <ProtectedRoute>
                <ShipmentDetail />
                </ProtectedRoute>
            } />
 <Route path='/portal/users' element={
                <ProtectedRoute>
                <Users />
                </ProtectedRoute>
            } />  <Route path='/portal/agents' element={
                <ProtectedRoute>
                <Agents />
                </ProtectedRoute>
            } /> 
            <Route path='/portal/reports' element={
                <ProtectedRoute>
                    <Reports />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipment/basic' element={
                <ProtectedRoute>
                    <Shipment />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipment/shipment-info' element={
                <ProtectedRoute>
                    <ShipmentInfo />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipment/courier-info' element={
                <ProtectedRoute>
                    <CourierInfo />
                </ProtectedRoute>
            } />
            <Route path='/portal/wallet' element={
                <ProtectedRoute>
                    <Wallet />
                </ProtectedRoute>
            } />
            <Route path='/portal/help' element={
                <ProtectedRoute>
                    <Help />
                </ProtectedRoute>
            } />
                        <Route path='/portal/orders' element={
                <ProtectedRoute>
                    <Orders />
                </ProtectedRoute>
            } />
               <Route path='/portal/order/view/:id' element={
                <ProtectedRoute>
                    <Order />
                </ProtectedRoute>
            } />

<Route path='/portal/shipment-orders' element={
                <ProtectedRoute>
                    <ShipmentOrders />
                </ProtectedRoute>
            } />
                    
                        <Route path='/portal/shipment/complete/:shipmentid' element={
                <ProtectedRoute>
                    <Confirmation />
                </ProtectedRoute>
            } />
            <Route path='/portal/quote/:id' element={
                <ProtectedRoute>
                <QuoteDetail />
                </ProtectedRoute>
            } />
            <Route path='/portal/quote/assign/:id' element={
                <ProtectedRoute>
                <QuoteAssign />
                </ProtectedRoute>
            } />
                        <Route path='/portal/quote/complete' element={
                <ProtectedRoute>
                <QuoteComplete />
                </ProtectedRoute>
            } />
                        <Route path='/portal/quote/edit/:id' element={
                <ProtectedRoute>
                <QuoteEdit />
                </ProtectedRoute>
            } />
            <Route path='/portal/quote/create' element={
                <ProtectedRoute>
                <QuoteEdit />
                </ProtectedRoute>
            } />
                        <Route path='/portal/commission' element={
                <ProtectedRoute>
                <Commission />
                </ProtectedRoute>
            } />
            <Route path='/portal/my-commission' element={
                <ProtectedRoute>
                <MyCommission />
                </ProtectedRoute>
            } />
        </Route>
        
        
    </Routes>
</BrowserRouter>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
